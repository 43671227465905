@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  height: 100%;
  border: 0 none;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Arial", "Helvetica",
    "sans-serif", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section:nth-child(odd) {
  border-bottom: 3px solid #999;
}
